.button-32 {
  background-color: #f7bb2d;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  transition: 200ms;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  font-size: 36px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-32:not(:disabled):hover,
.button-32:not(:disabled):focus {
  outline: 0;
  background: #f4e603;
  box-shadow: 0 0 0 5px rgba(32, 81, 244, 0.2), 0 3px 8px 0 rgba(0,0,0,.15);
}

.button-32:disabled {
  filter: saturate(0.2) opacity(0.5);
  -webkit-filter: saturate(0.2) opacity(0.5);
  cursor: not-allowed;
}
.title{
  padding: 10px;
  margin: 1%; 

}

div.main{ 
  background-image: url('../img/ash.gif');
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.botonPrincipal{ 
  padding: 0px;
}