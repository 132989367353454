/* div.mainso{ 
    background-image: url('../img/background.png');
    opacity: 1;
    background-size: cover;
    overflow: hidden;
} */

.paginado{
    cursor: pointer;
    touch-action: manipulation;
    font-size: 26px;
    text-align: center;
    box-sizing: border-box;
    border: 0;
}
ul.paginado {
    display: inline-block;
    padding: 0;
    margin: 0;
}
ul.paginado li {display: inline;}

ul.paginado li a {
    color: rgb(255, 255, 255);
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

ul.paginado li a.active {
    background-color: #4CAF50;
    color: rgb(0, 0, 0);
}

.titulo{
    font-family: 'Times New Roman', Times, serif;
    -webkit-text-stroke: 1.5px rgb(0, 0, 0);
    color: rgb(255, 255, 255); 
    font-size: 60px ;
    user-select: none;
}

.link{
    text-decoration: none;
    color: black;
}

.tipos{
    background-image: url('../img/planta.png');
}

.selectors{
    display:flex;
    justify-content: space-around;
    align-items: center;
}

.Ordername{
    margin: 20px;
    padding: 10px;
    border: 3px solid black;
    font-size: 18px;
    border-radius: 50% 50% 0% 0%;
    width: 30%;
    height: 50px;
}
.Orderatq{
    margin: 20px;
    padding: 10px;
    border: 3px solid black;
    font-size: 18px;
    border-radius: 50% 50% 0% 0%;
    width: 45%;
    height: 50px;
}

.filterCreated{
    margin: 20px;
}

.filterCreated{
    margin: 20px;
    padding: 10px;
    border: 3px solid black;
    font-size: 18px;
    border-radius: 50% 50% 0% 0%;
    width: 30%;
    height: 50px;
}

.filterType{
    margin: 20px;
    padding: 10px;
    border: 3px solid black;
    font-size: 18px;
    border-radius: 50% 50% 0% 0%;
    width: 30%;
    height: 50px;
}

.textOrdenamiento{
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.textFiltrado{
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.button-1 {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #000000;
  background-image: linear-gradient(45deg, #ffc42f 0%, #f0cc19  51%, #ffffff  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.button-1:active {
  transform: scale(0.75);
}
.linkbutton-1{
    text-decoration: none;
}

.buttonRefresh{
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #000000;
  background-image: linear-gradient(45deg, #f4de52 0%, #f6ebeb  51%, #000000  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonRefresh:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}
  
.buttonRefresh:active {
    transform: scale(0.75);
}

.PosicionamientoRefresh{
    display: flex;
    justify-content: center;
    align-items: center;
}