.mainD{ 
    height: 100vh;
    overflow: hidden;
    background-color: blanchedalmond;
    
}
.Detailmain{
   display: flex;
   flex-direction: row;
   align-items: center;
}

.positionIMG{
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.detailTipos{
    font-family: cursive;
    font-size: 40px;
    user-select: none; 
}

.detailh4{
    margin: 10px;
    padding: 10px;
    border: 3px solid black;
    background-color: rgba(176, 252, 176, 0.18);
    align-items: center;
    user-select: none; 
}
.detailh1{
    font-size: 60px;
    font-family: cursive;
    width: 100%;
    height: 100px; 
    user-select: none; 
}

.detailVolver{
    display: inline-block;
}

.aDetail{
    width: 1px;
}

.buttonVolverd{
    background-color: rgba(121, 255, 73, 0.39);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 25px;
    font-family: cursive;
}
.buttonVolverd:hover{
    background-color: rgba(218, 255, 215, 0.39);
    transform: scale(1.2);
}

.KillButtton{
    display: inline-block;
}

.ContenedoresButtons{
    display: flex;
    justify-content: space-around;
    margin: 20px;
    padding: 20px;
}

.borrarButton{
    background-color: rgba(255, 0, 0, 0.39);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 25px;
    font-family: cursive;

}
.borrarButton:hover{
    background-color: rgba(244, 135, 135, 0.39);
    transform: scale(1.2);
}