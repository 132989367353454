.header{
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 10px;

}
.pokeimagen{
    display: flex;
    margin-right: 730px;
    margin-left: 20px;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#ede974, #727272);
  }
.buscar {
    position: relative;
    display: flex;
    padding: 10px;
    text-align: center;
    align-items: center;
  }
  
  .buscar input {
    width: 0;
    height: 40px;
    padding: 0 20px;
    font-size: 18px;
    color: rgb(0, 0, 0);
    outline: none;
    border: 0px solid #886800;
    border-radius: 30px;
    transition: all 0.6s ease;
  }
  
  .btn {
    width: 55px;
    height: 55px;
    line-height: 55px;
    top: 0;
    right: 0;
    position: absolute;
    background: transparent;
    color: transparent; 
    text-align: center;
    align-items: center;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .buscar:hover input {
    width: 240px;
  }
  
  .buscar input:focus {
    width: 240px;
  }

  .btnAa{
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }